window.vcCarousel = [];

$(window).on("load", function() {
    $(`.grid-carousel:not(.inited)`).each(function() {
        let settings = $(this).data("carousel");
        settings = settings ? JSON.parse(settings) : {};
        vcCarousel.push(new VcCarousel(this, settings.gap || null, settings.scrollEnabled || false, settings.snapEnabled || false));
    });
},{once:true});


class VcCarousel {

    constructor(slider, gap = null, scrollEnabled = false, snapEnabled = false) {

        this.slider = slider;
        this.isDown = false;
        this.relaseDown = 0;
        this.inActionTime = 0;
        this.startX = 0;
        this.scrollLeft = 0;
        this.gap = gap;
        //if (this.gap) this.gap = parseInt(this.gap);

        this.scrollEnabled = scrollEnabled;
        this.snapEnabled = snapEnabled;

        this.init();
        this.events();
        this.snap();
    }

    init() {
/*        if (this.gap) {
            $(this.slider).css('--gap', `${this.gap}px`);
        }*/

        if (this.scrollEnabled) {
            setInterval(() => {
                if (this.lastScroll && this.lastScroll != -1 && Date.now() - this.lastScroll > 1000) {
                    this.snap();
                    this.lastScroll = -1;
                }
            }, 250);
        }
    }

    events() {
        const that = this;
        const slider = this.slider;

        window.addEventListener('resize', () => {
            that.snap();
        });

        slider.addEventListener('mouseup', (e) => {
            that.isDown = false;
            that.inActionTime = that.inActionTime - Date.now();
            that.relaseDown = Date.now();
            slider.classList.remove('active');
            that.snap();

            if (e.pointerId) slider.releasePointerCapture(e.pointerId);
        });

        slider.addEventListener('mousedown', (e) => {
            that.isDown = true;
            that.inActionTime = Date.now();
            slider.classList.add('active');
            that.startX = e.pageX - slider.offsetLeft;
            that.scrollLeft = slider.scrollLeft;

            if (e.pointerId) slider.setPointerCapture(e.pointerId);
            // Prevent dragg object
            e.preventDefault();
        });

        slider.addEventListener('mouseleave', () => {
            if (!that.isDown) return;
            that.isDown = false;
            that.inActionTime = that.inActionTime - Date.now();
            that.relaseDown = Date.now();
            slider.classList.remove('active');
            that.snap();
        });

        slider.addEventListener('mousemove', (e) => {
            if (!that.isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - that.startX); //scroll-fast
            slider.scrollLeft = that.scrollLeft - walk;
        });

        slider.addEventListener('touchstart', (e) => {
            that.isDown = true;
            that.inActionTime = Date.now();
            slider.classList.add('active');
            that.startX = e.touches[0].pageX - slider.offsetLeft;
            that.scrollLeft = slider.scrollLeft;
            that.startY = e.touches[0].pageY;
        });

        slider.addEventListener('touchend', () => {
            that.isDown = false;
            that.inActionTime = that.inActionTime - Date.now();
            that.relaseDown = Date.now();
            slider.classList.remove('active');
            that.snap();
        });

        $(slider).find("a[href]").on("click",function(event){
            // Cancel click event if user is scrolling
            console.log("Click", (that.relaseDown && Date.now() - that.relaseDown < 1000 && Math.abs(that.inActionTime) > 85));
            if (that.relaseDown && Date.now() - that.relaseDown < 1000 && Math.abs(that.inActionTime) > 85) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
        }, true);

        slider.addEventListener('touchmove', (e) => {
            if (!that.isDown) return;
            e.preventDefault();
            const x = e.touches[0].pageX - slider.offsetLeft;
            const walk = (x - that.startX); //scroll-fast
            slider.scrollLeft = that.scrollLeft - walk;

            // If user is moving up/down, cancel carousel scroll and let user scroll the page
            if (Math.abs(e.touches[0].pageY - that.startY) > 50) {
                that.isDown = false;
                that.inActionTime = that.inActionTime - Date.now();
                slider.classList.remove('active');
            }
        });

        if (!this.scrollEnabled) return;
        slider.addEventListener('wheel', (e) => {
            e.preventDefault();
            that.lastScroll = Date.now();
            slider.scrollTo({
                left: slider.scrollLeft + (e.deltaY * 2),
                behavior: 'smooth',
            });
        });

    }

    snap() {
        if (!this.snapEnabled) return;

        const slider = this.slider;
        let closest = null;

        // Get move direction left/right
        let direction = 0;
        if (slider.scrollLeft > this.scrollLeft) {
            direction = 250;
        } else {
            direction = -250;
        }

        let children = slider.children;
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            if (closest == null || Math.abs(child.offsetLeft - slider.scrollLeft) < Math.abs(closest.offsetLeft - slider.scrollLeft - direction)) {
                closest = child;
            }
        }

        let lastElement = children[children.length - 1];
        if (lastElement.offsetLeft + lastElement.offsetWidth - slider.scrollLeft < slider.offsetWidth) {
            closest = lastElement;
        }

        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            child.classList.remove("active");
        }

        // Add border to the closest card
        if (closest) {
            closest.classList.add("active");
            // Scroll sliderx to the closest card
            setTimeout(function() {
                slider.scrollTo({
                    left: closest.offsetLeft,
                    behavior: 'smooth',
                })
            }, 250);
        }
    }


}